<template>
  <component
    :is="to ? 'router-link' : 'button'"
    :to="to"
    :type="type"
    :class="[variants, sizeClass]"
    class="group flex w-fit items-center justify-center gap-2 rounded-4xl px-6 font-medium transition-all"
  >
    <span
      class="flex gap-2 pointer-events-none"
      :class="order"
    >
      <slot>
        {{ label }}
      </slot>
    </span>

    <template v-if="showIcon">
      <div
        v-if="variant === 'secondary'"
        :class="[iconBadgeSpacing, iconBadgeSize]"
        class="flex pointer-events-none flex-none items-center justify-center rounded-4xl bg-black p-1 transition-all group-hover:bg-secondary"
      >
        <Icon
          :name="iconName"
          class="transition-all"
        />
      </div>
      <Icon
        v-else
        :name="iconName"
        class="flex-none pointer-events-none transition-all"
        :class="iconClass"
      />
    </template>
  </component>
</template>

<script setup lang="ts">
import type {RouteLocationRaw} from 'vue-router';

const props = withDefaults(defineProps<{
  to?: RouteLocationRaw | undefined
  label?: string
  type?: string,
  iconPosition?: 'left' | 'right'
  variant?: 'primary' | 'primary-outline' | 'secondary' | 'secondary-outline',
  iconName?: string,
  showIcon?: boolean,
  size?: 'small' | 'default'
}>(), {
  to: undefined,
  label: '',
  type: 'button',
  iconPosition: 'right',
  variant: 'primary',
  iconName: 'solar:arrow-right-linear',
  showIcon: true,
  size: 'default',
});

const variants = computed(() => ({
  'primary': 'text-white bg-primary hover:bg-tertiary',
  'primary-outline': 'text-primary hover:bg-primary hover:text-white border border-primary bg-white',
  'secondary': 'text-white bg-secondary hover:bg-black',
  'secondary-outline': 'text-black hover:bg-black hover:text-white [&.active]:text-white [&.active]:bg-black border border-black bg-transparent',
}[props.variant]));

const sizeClass = computed(() => {
  return props.size === 'small' ? 'text-base h-[46px]' : 'text-lg h-14';
});

const iconClass = computed(() => {
  return props.variant === 'primary' && props.iconName === 'solar:arrow-right-linear'
    ? 'group-hover:-rotate-45'
    : '';
});

const order = computed(() => {
  return props.iconPosition === 'left' ? 'order-1' : '';
});

const iconBadgeSpacing = computed(() => ({
  left: '-ml-4',
  right: '-mr-4',
}[props.iconPosition]));

const iconBadgeSize = computed(() => ({
  small: 'size-7',
  default: 'size-8',
}[props.size]));
</script>
